<template>
  <div>
    <h4 class="mb-4">{{ event.title }}</h4>

    <b-form id="generate-schedule" @submit.prevent="onSubmit()" @reset.prevent="onReset">
      <!-- over-all settings -->
      <div v-if="!settingsConfirmed">
        <b-card
          title="Confirm Settings"
          sub-title="applies to all groups."
        >
          <b-alert class="mt-4" show>
            <p class="m-0">Confirm your over-all settings here. These settings will apply to all groups created for this schedule.</p>
          </b-alert>

          <b-row>
            <b-col cols="12" lg="3">
              <b-form-group
                id="fieldset-start-date"
                description="The date of the 1st game"
                label="Begins on (date)"
                label-for="start-date"
                class="mb-0"
                label-cols="12"
                label-cols-lg="6"
              >
              <v-date-picker
                v-model="startDate"
                name="startDate"
                id="start-date"
                :input-props="{
                  class: 'form-control'
                }"
              />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                id="fieldset-start-time"
                description="The time of the 1st game"
                label="at (time)"
                label-for="start-time"
                class="mb-0"
                label-cols="12"
                label-cols-lg="6"
              >
              <b-form-input
                v-model="startTime"
                name="startTime"
                id="start-time"
              />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                id="fieldset-fields"
                description="Number of fields available"
                label="Fields Available"
                label-for="fields"
                class="mb-0"
                label-cols="12"
                label-cols-lg="6"
              >
              <b-form-input
                v-model.number="numPlayingFields"
                name="numPlayingFields"
                type="number"
                min="1"
                id="fields"
              />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                label="Team Labels"
                label-for="label-type"
                class="mb-0"
                label-cols="12"
                label-cols-lg="6"
              >
                <b-form-select name="labelType" :options="[
                  { value: 'alpha', text: 'Alphabetical' },
                  { value: 'numeric', text: 'Numerical' }
                ]" v-model="labelType" id="label-type"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col cols="12" lg="3">
              <b-form-group
                id="fieldset-game-days"
                label="Game Days"
                label-for="game-days"
                class="mb-0"
                label-cols="12"
                label-cols-lg="6"
              >
                <b-form-input
                  v-model.number="maxGameDays"
                  name="maxGameDays"
                  type="number"
                  min="1"
                  id="game-days"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-checkbox
                id="checkbox-inc-meet-greet"
                v-model="meetAndGreet"
                name="inc-meet-greet"
                :value="true"
                :unchecked-value="false"
                class="mt-2"
              >
                First day Meet & Greet
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>

        
        <b-row>
          <b-col></b-col>
          <b-col class="mt-4" cols="12" lg="2">
            <b-button variant="success" @click="settingsConfirmed = !settingsConfirmed" block>Continue >></b-button>
          </b-col>
        </b-row>

      </div>
      <div v-else>
        <!-- new group settings -->
        <b-card
          title="Add Group(s)"
          sub-title="Create as many groups as are needed."
        >
          <b-alert class="mt-4" show>
            <p class="m-0">Create your groups here; for instance, age groups. Once you generate one group, you may generate more groups to be included in the same schedule.</p>
          </b-alert>

          <!-- group type -->
          <div v-if="!groupType">
            <b-button-group>
              <b-button @click="createJrGroup()">Jr Group</b-button>
              <b-button @click="groupType = 'teamsGroup'">Teams Group</b-button>
            </b-button-group>
          </div>

          <!-- create group -->
          <div v-else class="container-fluid">
            <b-row v-if="groupType === 'teamsGroup'">
              <b-col cols="12" lg="5" class="generate-schedule-item bg-light p-2 border">
                <h4>Group Label</h4>
                <p>
                  Set a group label here. For instance, 6 - 9.
                </p>
                <b-form-group
                  label-cols="4"
                  label-size="lg"
                  label-class="sr-only"
                  label="Group Label"
                  label-for="group"
                  class="m-0 text-center"
                >
                  <b-form-input
                    :state="($v.groupName.$model === '' ? null : !$v.groupName.$error)"
                    v-model.trim="$v.groupName.$model"
                    name="groupName"
                    :maxlength="formMaxLengthGroupName"
                    id="group"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="5" class="generate-schedule-item bg-light p-2 border">
                <h4>Teams in Group</h4>
                <p>
                  Set the number of teams for this group in even numbers.
                </p>
                <b-form-group
                  label-cols="4"
                  label-size="lg"
                  label-class="sr-only"
                  label="Teams"
                  label-for="num-teams"
                  class="m-0 text-center"
                  description="Even numbers from 2 &amp; 10"
                >
                  <b-form-input
                    :state="($v.totalTeams.$model === '' ? null : !$v.totalTeams.$error)"
                    v-model.number="$v.totalTeams.$model"
                    name="totalTeams"
                    type="number"
                    min="2"
                    max="100"
                    step="2"
                    id="num-teams"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="2" class="p-2">
                <div class="d-none d-lg-block pt-4 pb-5"></div>
                <!-- b-form-checkbox
                  v-if="totalTeams < 3"
                  id="checkbox-is-jr"
                  v-model="groupIsJr"
                  name="checkbox-is-jr"
                  :value="true"
                  :unchecked-value="false"
                  class="mb-2"
                >
                  Jr Group
                </b-form-checkbox -->
                <b-button variant="success" type="submit" size="lg" block>Add Group</b-button>
                <b-button variant="warning" size="lg" @click="groupType = null" block>Group Type</b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <div class="text-right mb-4" v-if="Object.keys(schedules).length">
          <b-button-group size="sm">
            <b-button @click="groupsAreEditable = !groupsAreEditable" :variant="(groupsAreEditable ? 'warning' : 'secondary')">
              <i :class="groupsAreEditable ? 'fa fa-check' : 'fa fa-pencil'"></i> {{ (groupsAreEditable ? 'Edit' : 'Edit') }} Groups
            </b-button>
            <b-button @click="scheduleImage()" variant="primary" :disabled="groupsAreEditable"><i class="fa fa-download"></i> Download Schedule</b-button>
          </b-button-group>
        </div>

        <!-- schedules -->
        <b-card
          v-if="Object.keys(schedules).length"
          :title="'Schedule for: ' + event.title"
          ref="eventschedule"
        >
          <b-alert class="mt-4" show>
            <p class="m-0">Color variations indicate time differences.</p>
          </b-alert>

          <!-- begin schedules -->
          <template v-for="(group, name, i) in schedules">

            <!-- edit group -->
            <div v-if="groupsAreEditable && editGroup[i]" :key="'edit-group-' + i" class="mb-4">
              <b-form-group
                label-size="lg"
                label="Group"
                label-for="team-group"
              >
                <b-form-input
                  name="team_group"
                  id="team-group"
                  size="lg"
                  :maxlength="formMaxLengthGroupName"
                  v-model="editGroup[i].name"
                  class="autowidth"
                  placeholder="Group Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-size="lg"
                label="Teams"
              >
                <b-form-input
                  v-for="(team, i2) in editGroup[i].teams"
                  :name="'team_group[' + i + ']'"
                  :id="'team-name' + i"
                  size="lg"
                  maxlength="10"
                  v-model="editGroup[i].teams[i2].name"
                  class="autowidth"
                  placeholder="Team Name"
                  :key="i2"
                ></b-form-input>
              </b-form-group>
              <b-button @click="onEditGroup(i)">Done</b-button>
            </div>

            <!-- schedule -->
            <b-table :items="group" :key="i" class="schedule" hover responsive caption-top>

              <!-- caption label -->
              <template v-if="!editGroup[i]" #table-caption>
                <h3>
                  {{ name.toUpperCase().split('|')[0] }} <small class="float-right d-print-none" v-if="groupsAreEditable"><a
                    href="javascript:;"
                    title="Edit Team Names"
                    class="text-small"
                    @click="$set(editGroup, i, { ...groups[i] })"
                  >Edit Group</a> | <a
                    href="javascript:;"
                    title="Remove this group"
                    class="text-small"
                    @click="onRemoveGroup(i)"
                  >Remove Group</a></small>
                </h3>
              </template>

              <!-- template #head()="scope">
                <div class="text-nowrap">
                  {{ scope }}
                </div>
              </template -->

              <!-- field -->
              <template #cell(field)="row">
                <live-edit
                  v-model="schedules[name][row.index].field"
                  placeholder="field name"
                  :edit-mode="!groupsAreEditable"
                ></live-edit>
              </template>

              <!-- time -->
              <template #cell(time)="row">
                <live-edit
                  v-model="schedules[name][row.index].time"
                  placeholder="field name"
                  :edit-mode="!groupsAreEditable"
                ></live-edit>
              </template>

              <template #cell()="row">
                <live-edit
                  v-model="schedules[name][row.index][row.field.key]"
                  :placeholder="row.field.label"
                  :edit-mode="!groupsAreEditable"
                ></live-edit>
              </template>

            </b-table>
          </template>
          <!-- end schedules -->
        </b-card>
        
        <div class="text-right mb-4" v-if="Object.keys(schedules).length">
          <b-button-group size="sm">
            <b-button @click="groupsAreEditable = !groupsAreEditable" :variant="(groupsAreEditable ? 'warning' : 'secondary')">
              <i :class="groupsAreEditable ? 'fa fa-check' : 'fa fa-pencil'"></i> {{ (groupsAreEditable ? 'Edit' : 'Edit') }} Groups
            </b-button>
            <b-button @click="scheduleImage()" variant="primary" :disabled="groupsAreEditable"><i class="fa fa-download"></i> Download Schedule</b-button>
          </b-button-group>
        </div>
      
        <!-- reset schedules -->
        <b-row align-v="baseline">
          <b-col class="mt-4 text-left" cols="4">
            <b-button size="sm" variant="warning" @click="settingsConfirmed = !settingsConfirmed">&larr;&larr; Settings</b-button>
          </b-col>
          <b-col v-if="Object.keys(schedules).length > 0" class="mt-4 text-center" cols="4">
            <b-button size="sm" variant="danger" type="reset">Reset All</b-button>
          </b-col>
          <b-col v-if="Object.keys(schedules).length > 0" class="mt-4" cols="4">
            <b-button variant="success" @click="onSave" block><i class="fa fa-check"></i> Save</b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Tournament from 'round-robin-tournament'
// Also consider https://github.com/tournament-js
import { required, between, integer } from 'vuelidate/lib/validators'
import html2canvas from 'html2canvas'
import LiveEdit from '@/components/LiveEdit'

const mustBeEven = (value) => value % 2 === 0
// start with _ because this.currentId starts with 1
const ALPHA = "_ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
const MAX_GAMEDAYS = 8 // @TODO pull from settings
// groups @example: {
//   '3-5': [
//     { id: 1, name: "A" },
//     { id: 2, name: "B" },
//     { id: 3, name: "C" },
//     { id: 4, name: "D" },
//     { id: 5, name: "E" },
//     { id: 6, name: "F" },
//   ],
//   '6-9': [
//     { id: 7, name: "G" },
//     { id: 8, name: "H" },
//     { id: 9, name: "I" },
//     { id: 10, name: "J" },
//   ],
//   '10-16': [
//     { id: 11, name: "K" },
//     { id: 12, name: "L" },
//     { id: 13, name: "M" },
//     { id: 13, name: "N" },
//   ]
// }
export default {
  name: 'EventScheduleGenerator',
  components: { LiveEdit },
  data () {
    return {
      eid: null,
      event: {},
      scheduleId: null,

      startDate: null,
      startTime: '10:00:00',
      numPlayingFields: 3,
      labelType: 'alpha',
      maxGameDays: MAX_GAMEDAYS,
      meetAndGreet: true,
      settingsConfirmed: false,

      formMaxLengthGroupName: 40,
      groupType: null,
      groupName: '',
      totalTeams: 2,
      groupIsJr: false,

      jrId: 1,
      currentId: 1,
      groups: [],
      groupsAreEditable: false,
      editGroup: [],
      schedules: {},
      lastOccurrenceText: 'TBD'
    }
  },
  /**
   * vuelidate
   */
  validations: {
    groupName: {
      required
    },
    totalTeams: {
      required,
      integer,
      mustBeEven,
      between: between(2, 10)
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'currentUser'
    ]),
    /**
     * label for jr team
     */
    jrGroupLabel () {
      return 'Junior' // + this.event.sport.label
    }
  },
  watch: {
    startDate () {
      this.generateMatches()
    },
    meetAndGreet () {
      this.generateMatches()
    },
    maxGameDays () {
      this.generateMatches()
    },
    numPlayingFields () {
      // this.generateMatches()
    },
    labelType () {
      this.updateTeams()
    }
  },
  methods: {
    ...mapActions([
      'getEvent',
      'putEvent',
      'getEventSchedules',
      'postEventSchedule',
      'putEventSchedule'
    ]),
    /**
     * initial date label of schedule row
     * @param {int} i week number in schedule
     */
    iniDateLabel (i) {
      return this.$moment(this.startDate, 'dddd MMMM Do YYYY HH:mm:ss').add(i, 'w').format('MM/DD')
    },
    /**
     * creates a new group of teams
     */
    addNewGroup () {
      // validate
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.showError({ message: 'Please check the form and try again.' })
        return false
      }

      // initialize new group
      const teams = []

      // add teams to group (numeric|alpha)
      for (let i = 0; i < this.totalTeams; i++) {
        if (this.labelType === 'alpha' && typeof ALPHA[this.currentId] === 'undefined') {
          this.labelType = 'numeric'
          this.currentId = 1
        }
        teams.push({
          name: this.groupIsJr ? 'Jr' : this.labelType === 'alpha' ? ALPHA[this.currentId] : this.currentId,
          id: this.currentId
        })
        if (!this.groupIsJr) {
          this.currentId++
        }
      }

      // add new group to groups
      this.groups.push({
        name: this.groupName,
        isJr: this.groupIsJr,
        teams
      })
      this.$set(this.editGroup, (this.groups.length - 1), null)
      this.generateMatches()
      this.showSuccess({ message: this.groupName + ' successfully created' })
      // reset group
      this.groupName = ''
      this.groupIsJr = false
      // this.groupType = null
    },
    createJrGroup () {
      this.groupName = 'Junior|' + this.jrId
      this.jrId++
      this.groupIsJr = true
      this.totalTeams = 2
      this.addNewGroup()
    },
    /**
     * generates matches for team groups
     */
    generateMatches () {
      this.schedules = {}
      const maxGamedays = this.maxGameDays
      const maxMatches = (maxGamedays - (this.meetAndGreet ? 2 : 1))
      const schedule = {} // { ...this.schedules }
      const weekLabel = {}
      const weekLabelUse = !this.startDate ? 'text' : 'date'
      let tournament
      let groupName
      let isJr

      for (let k in this.groups) {
        groupName = this.groups[k].name
        isJr = this.groups[k].isJr
        let gamedays = []
        tournament = new Tournament(this.groups[k].teams)
        gamedays = gamedays.concat(tournament.matches)
        // console.log(gamedays)
        // concat matches if we need more game days to reach maxGamedays
        while (gamedays.length < maxMatches) {
          gamedays = gamedays.concat(tournament.matches)
        }

        schedule[groupName] = []

        for (let i = 0; i < maxMatches; i++) {
          weekLabel.text = 'week ' + (i + 2)
          weekLabel.date = this.iniDateLabel((i + (this.meetAndGreet ? 1 : 0)))

          for (let i2 in gamedays[i]) {
            for (let i3 in gamedays[i][i2]) {
              if (typeof schedule[groupName][i2] === 'undefined') {
                schedule[groupName][i2] = {}
              }
              if (!isJr) {
                schedule[groupName][i2][weekLabel[weekLabelUse]] = `${gamedays[i][i2][0].name} vs ${gamedays[i][i2][1].name}`
              } else {
                schedule[groupName][i2][weekLabel[weekLabelUse]] = this.jrGroupLabel
              }
            }
            // @FIXME (last occurrence) ? Add last game day here - before  the addition of fields and times - so it doesn't change
            // if (i === (maxMatches - 1)) {
            //   schedule[groupName][i2][weekLabel[weekLabelUse]] = (!isJr) ? this.lastOccurrenceText : this.jrGroupLabel
            // }
          }
        }


        const weekLabelStart = !this.startDate ? 'week 1' : this.iniDateLabel(0)
        const weekLabelEnd = !this.startDate ? 'week ' + maxGamedays : this.iniDateLabel((maxGamedays - 1))

        // insert field, time, first and last game day labels
        let currentField = 1
        let time = this.$moment().format("YYYY-MM-DD") + `T${this.startTime}`
        let colorIndex = 0
        let colorOptions = ['primary','secondary','success','info','warning','light'] // ,'danger'

        for (let group in schedule) {
          schedule[group] = schedule[group].map((e, i) => {
            let fieldName = 'Field ' + currentField
            let newSched = {
              field: (this.schedules[group] ? this.schedules[group][i].field : fieldName),
              time: (this.schedules[group] ? this.schedules[group][i].time : this.$moment(time).format("h:mma"))
            }
            if (this.meetAndGreet) {
              newSched[weekLabelStart] = 'Meet & Greet'
            }
            
            newSched = { ...newSched, ...e }
            newSched[weekLabelEnd] = this.lastOccurrenceText // @FIXME (last occurrence) remove and create before this for loop
            newSched['_rowVariant'] = colorOptions[colorIndex]

            if (currentField === this.numPlayingFields) {
              currentField = 1
              time = this.$moment(time).add(1, 'h')
              colorIndex++
              if (colorIndex === colorOptions.length) {
                colorIndex = 0
              }
            } else {
              currentField++
            }

            return newSched
          });
        }
      }

      this.schedules = schedule // set schedules
    },

    onSubmit () {
      this.addNewGroup()
    },

    onReset () {
      this.currentId = 1
      this.groupType = null
      this.groups = []
      this.schedules = {}
      this.totalTeams = 2
      // @XXX pull from setting ? local cookie
      this.startTime = '10:00:00'
      this.numPlayingFields = 3
    },
    /**
     * Edits group elements
     * @param {int} key 
     */
    onEditGroup (key) {
      this.groups[key] = { ...this.editGroup[key] }
      this.$set(this.editGroup, key, null)
      this.generateMatches()
    },
    /**
     * removed a group
     * @param {int} key 
     */
    onRemoveGroup (key) {
      this.groups.splice(key, 1)
      this.generateMatches()
    },
    /**
     * creates and downloads an image of the schedule
     */
    async scheduleImage () {
      this.groupsAreEditable = false
      const el = this.$refs.eventschedule

      const options = {
        type: "dataURL",
      }
      const printCanvas = await html2canvas(el, options)

      const link = document.createElement("a")
      link.setAttribute("download", this.event.title.replace(/[^0-9a-z-\s]/ig, '').replace(/\s/g, '_').toLowerCase() + ".png")
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      )
      link.click()
    },
    onSave () {
      const settings = {
        startDate: this.startDate.toISOString(),
        startTime: this.startTime,
        numPlayingFields: this.numPlayingFields,
        labelType: this.labelType,
        maxGameDays: this.maxGameDays,
        meetAndGreet: this.meetAndGreet
      }
      const eventSchedule = {
        event: this.event['@id'],
        title: this.event.title,
        settings,
        createdBy: this.currentUser['@id'],
        groups: this.groups,
        schedules: this.schedules
      }
      if (!this.scheduleId) {
        this.postEventSchedule(eventSchedule)
      } else {
        eventSchedule.id = this.scheduleId
        this.putEventSchedule(eventSchedule)
      }
    },

    /**
     * gets the event details
     */
    async doGetEvent () {
      const event = await this.getEvent({
        id: this.eid,
        business: this.business.id
      }).then(resp => {
        if (!resp) {
          this.showError({ message: 'Could not find event' })
          this.$router.push({ name: 'events' })
          return false
        }
        this.event = resp
        this.startDate = new Date(resp.startDate)
      })
      // look for existing schedules
      await this.getEventSchedules({
        event: this.eid
      }).then((resp) => {
        if (!resp.length) {
          return
        }
        // load initial schedule
        const e = resp[0] // @FIXME: add option to load a specific schedule (jr/league/etc...)

        this.startDate = e.settings.startDate
        this.startTime = e.settings.startTime
        this.numPlayingFields = e.settings.numPlayingFields
        this.labelType = e.settings.labelType
        this.maxGameDays = e.settings.maxGameDays
        this.meetAndGreet = e.settings.meetAndGreet

        this.title = e.title
        this.groups = e.groups
        this.schedules = e.schedules
      })
      return event
    }
  },
  async mounted () {
    this.eid = this.$route.params.eid
    if (this.eid) {
      await this.doGetEvent()
    } else {
      this.$router.push({ name: 'events' })
    }
  }
}
</script>

<style lang="scss">
#generate-schedule {
  p {
    /* min-height: 40px; */
  }
  .generate-schedule-item {
    margin-bottom: 12px;
  }
}
</style>
